import Vue from "vue";

export const register = (x, payload) =>
  Vue.$axios
    .post("user/register", payload)
    .then(() => true)
    .catch(error => {
      let error_code = "GENERIC";
      if (
        error.response.status === 400 &&
        "error_code" in error.response.data
      ) {
        error_code = error.response.data.error_code;
      }
      throw error_code;
    });

export const create_by_email = (x, payload) =>
  Vue.$axios
    .post("customer/create_by_email", payload)
    .then(response => {
      if (response.data.error) {
        response.status = 400;
        throw {
          response
        };
      } else {
        return true;
      }
    })
    .catch(error => {
      let error_code = "GENERIC";
      if (error.response.status === 400 && "error" in error.response.data) {
        error_code = error.response.data.error;
      }
      throw error_code;
    });

export const activate_after_sign_in_link = (x, payload) =>
  Vue.$axios
    .post("user/activate_user_after_sign_in_link", payload)
    .then(response => {
      if (response.data.error) {
        response.status = 400;
        throw {
          response
        };
      } else {
        return true;
      }
    })
    .catch(() => Promise.reject());

export const request_password_reset = (x, payload) =>
  Vue.$axios
    .post("user/password/request_reset", payload)
    .then(() => true)
    .catch(() => Promise.reject());

export const request_email_change = (x, payload) =>
  Vue.$axios
    .post("user/email/request_change", payload)
    .then(() => true)
    .catch(() => Promise.reject());

export const set_password = (x, payload) =>
  Vue.$axios
    .post("user/password/set", payload)
    .then(() => true)
    .catch(() => Promise.reject());

export const set_email = (x, payload) => {
  return Vue.$axios
    .post("user/email/set", payload)
    .then(() => true)
    .catch(() => Promise.reject());
};

export const initialize = ({ commit, dispatch }) =>
  Promise.all([dispatch("get"), dispatch("get_email_change_request")])
    .then(() => commit("INITIALIZED"))
    .catch(err => throw err);

export const get = ({ commit }) =>
  Vue.$axios
    .get("user/me")
    .then(result => {
      commit("SET", result.data);
    })
    .catch(() => Promise.reject());

export const get_email_change_request = ({ commit }) =>
  Vue.$axios
    .get("user/me/email_change_request")
    .then(result => {
      commit("SET_EMAIL_CHANGE", result.data);
    })
    .catch(() => Promise.reject());

export const request_customer_data_deletion = () =>
  Vue.$axios
    .get("user/support/request-delete-data")
    .catch(() => Promise.reject());

export const request_customer_account_deletion = () =>
  Vue.$axios
    .get("user/support/request-delete-account")
    .catch(() => Promise.reject());

// eslint-disable-next-line no-unused-vars
export const validatePasswordResetToken = ({ commit }, payload) => {
  const token = payload.token;
  return Vue.$axios
    .post("user/password/validate_password_reset_token", { token })
    .then(response => {
      // Token is valid
      return Promise.resolve(response.data);
    })
    .catch(error => {
      // Check the error response and categorize it
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error;
        if (errorMessage === "INVALID_PASSWORD_RESET_TOKEN") {
          return Promise.reject("INVALID_TOKEN");
        } else if (errorMessage === "EXPIRED_PASSWORD_RESET_TOKEN") {
          return Promise.reject("EXPIRED_TOKEN");
        }
      }
      // For other errors, just reject with the error itself
      return Promise.reject(error);
    });
};
